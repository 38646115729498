import router from '@/router'
import store from '@/store'

// 没有登录也可以进入的页面白名单
const whiteList = ['/login', '/test', '/test2']

/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 1.用户已登陆，则不允许进入 login
  // 2.用户未登录，只允许进入 login
  if (store.state.token) {
    if (to.path === '/login') {
      next('/')
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      // ElMessage.error('token')
      next('/login')
    }
  }
})
