import { createStore } from 'vuex'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import router from '@/router'
import { ElMessage } from 'element-plus'

export default createStore({
  state: {
    token: getItem('token') || '',
    userInfo: getItem('userInfo') || {}
  },
  mutations: {
    // 设置token
    setToken(state, token) {
      state.token = token
      setItem('token', token)
    },
    // 设置用户信息
    setUserInfo(state, info) {
      state.userInfo = info
      setItem('userInfo', info)
    }
  },
  actions: {
    /**
     *  退出登录
     */
    logout() {
      removeAllItem()
      router.go(0)
      ElMessage.success('退出登录成功')
    }
  }
})
