<template>
  <div class="navbar">
    <div class="navbar-box">
      <div class="nav-icon">
        <img src="@/assets/icon/loginIcon.png" alt="" />
      </div>
      <div
        :class="
          router.currentRoute.value.fullPath.includes('order')
            ? 'nav-item active'
            : 'nav-item'
        "
        @click="goPage('/')"
      >
        数据统计
      </div>
      <div
        :class="
          router.currentRoute.value.fullPath.includes('recordquery')
            ? 'nav-item active'
            : 'nav-item'
        "
        @click="goPage('/recordquery')"
      >
        记录查询
      </div>
      <div
        :class="
          router.currentRoute.value.fullPath.includes('formmanage')
            ? 'nav-item active'
            : 'nav-item'
        "
        @click="goPage('/formmanage')"
      >
        工单管理
      </div>

      <div
        :class="
          router.currentRoute.value.fullPath.includes('exportprinting')
            ? 'nav-item active'
            : 'nav-item'
        "
        @click="goPage('/exportprinting')"
      >
        数据导出及打印
      </div>
      <div class="logout">
        <div class="user-img">
          <img :src="info.avatar" alt="" />
        </div>
        <div class="text" @click="logout">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const goPage = (path) => {
  router.push(path)
}

const info = ref({})

const store = useStore()
const getuserInfo = () => {
  info.value = store.state.userInfo
  // console.log(info.value)
}
setTimeout(() => {
  getuserInfo()
}, 100)

const logout = () => {
  store.dispatch('logout')
}
</script>
<style lang="scss" scoped>
.navbar {
  height: 100px;
  width: 100%;
  background-color: rgba(83, 133, 243, 1);

  .navbar-box {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .nav-icon {
      width: 182px;
      height: 60px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .nav-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 120px;
      font-size: 15px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }

    .logout {
      width: 130px;
      display: flex;
      color: #fff;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;

      .user-img {
        width: 50px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    .nav-item:hover {
      background-color: rgba(56, 100, 197, 1);
      color: #fff;
    }

    .active {
      background-color: rgba(56, 100, 197, 1);
      color: #fff;
    }
  }
}
</style>
