<!--  -->
<template>
  <router-view />
</template>

<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  document.body.style.setProperty('--el-disabled-color-base', '#333')
  document.body.style.setProperty('--el-table-font-color', '#000')
})
</script>
<style lang="scss" scoped></style>
