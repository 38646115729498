import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/layout/index'

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    redirect: '/order/orderecord',
    component: layout,
    children: [
      {
        path: '/order/orderecord',
        name: 'orderecord',
        component: () => import('@/views/orderRecord'),
        meta: {
          title: 'orderecord'
        }
      },
      {
        path: '/order/orderdata',
        name: 'orderdata',
        component: () => import('@/views/orderData'),
        meta: {
          title: 'orderdata'
        }
      },
      {
        path: '/order/submitNum',
        name: 'submitNum',
        component: () => import('@/views/submitNum'),
        meta: {
          title: 'submitNum'
        }
      },
      {
        path: '/order/orderecord',
        name: 'orderecord',
        component: () => import('@/views/orderRecord'),
        meta: {
          title: 'orderecord'
        }
      },
      {
        path: '/formmanage',
        name: 'formmanage',
        component: () => import('@/views/orderManage'),
        meta: {
          title: 'formmanage'
        }
      },
      {
        path: '/importData/:id',
        name: 'importData',
        component: () => import('@/views/importData'),
        meta: {
          title: 'importData'
        }
      },
      {
        path: '/recordquery',
        name: 'recordquery',
        component: () => import('@/views/recordQuery'),
        meta: {
          title: 'recordquery'
        }
      },
      {
        path: '/exportprinting',
        name: 'export-printing',
        component: () => import('@/views/export-printing'),
        meta: {
          title: 'export-printing'
        }
      },
      {
        path: '/exportprinting/datadownload',
        name: 'datadownload',
        component: () => import('@/views/dataDownload'),
        meta: {
          title: 'datadownload'
        }
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test'),
        meta: {
          title: 'test'
        }
      },
      {
        path: '/test2',
        name: 'test2',
        component: () => import('@/views/test2'),
        meta: {
          title: 'test2'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
